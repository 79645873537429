.portfolio__item {
    display: flex;
    flex-direction: column;
    background: var(--color-bg-variant);
    padding: 1.3rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);
}

.portfolio__item-container{
    flex: 1;
}

.portfolio__item-language {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1.2rem;
    margin-right: 1rem;
    width: 15%;
    color: rgb(10, 9, 9);
    padding: 0.5rem;
    border-radius: 1.5rem;
    overflow: hidden;
    background-color: rgb(49, 120, 198);
}

.portfolio__item-technology {
    font-size: 1rem;
}

.portfolio__item h3 {
    margin: 1.2rem 0 2rem;
}

.portfolio__item-cta {
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;
}

.swiper-pagination-clickable .swiper-pagination-bullet {
    background: var(--color-primary);
}

/* MEDIA QUERY (MEDIUM DEVICES) */
@media screen and (max-width: 1024px) {
    .portfolio__item-language{
        width: 22%;
    }
}

/* MEDIA QUERY (SMALL DEVICES) */
@media screen and (max-width: 600px) {
    .portfolio__item-language {
        width: 35%;
        margin-bottom: 8%;
    }

    .portfolio__item-technology {
        font-size:x-small;
    }
}

