header {
    height: 100vh;
    padding-top: 7rem;
    overflow: hidden;
}

.header__container {
    text-align: center;
    height: 100%;
    position: relative;
}

/* CTA */
.btn {
    margin-top: 2.5rem;
    margin-right: 1rem;
    display: flex;
    justify-content: center;
}

/* HEADER SOCIALS */
.header__socials {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
    position: fixed;
    left: 0;
    bottom: 3rem;
    top: 50%;
    left: 5%;
}

.header__socials::after {
    content: "";
    width: 1px;
    height: 2rem;
    background: var(--color-primary);
}

/* ME */
.me {
    background: linear-gradient(var(--color-primary), transparent);
    width: 22rem;
    height: 28rem;
    position: absolute;
    left: calc(50% - 11rem);
    margin-top: 4rem;
    border-radius: 12rem 12rem 0 0;
    overflow: hidden;
    padding: 5rem 1.5rem 1.5rem 1.5rem;
}

.me img {
    border-radius: 20%;
    width: 100%;
    height: 65%;
}

/* SCROLL DOWN */
.scroll__down {
    position: absolute;
    right: -2.3rem;
    bottom: 5rem;
    transform: rotate(90deg);
    font-weight: 300;
    font-size: 0.9rem;
}

/* MEDIA QUERY (MEDIUM DEVICES) */
@media screen and (max-width: 1024px) {
    header{
        height: 100vh;
    }
}

/* MEDIA QUERY (SMALL DEVICES) */
@media screen and (max-width: 600px) {
    header{
        height: 100vh;
    }

    .header__socials, .scroll__down {
        display: none;
    }

    .typewriter {
        font-size: 1.1rem;
    }
    
}
